import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
import { SessionService } from './session.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public statusRA = false;
  webSocket: WebSocket;

  private chatMessage = new BehaviorSubject<Object>(undefined);
  public chatMessage$ = this.chatMessage.asObservable();

  private auctionNotification = new BehaviorSubject<Object>(undefined);
  public auctionNotification$ = this.auctionNotification.asObservable();

  constructor(
    private sessionService: SessionService,
    private globalService: GlobalService,
    private deviceService: DeviceDetectorService
  ) { }

  connectionWebSocket(){
    if(!this.webSocket || (this.webSocket && this.webSocket.readyState === this.webSocket.CLOSED)){
      let url = this.getInfoUserCrypto();
      this.webSocket = new WebSocket(url);
      this.webSocket.onopen = (event) => {
        this.statusRA = true;
      };
  
      this.webSocket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if(this.validateAuctionWss(data.action)){
          this.auctionNotification.next(data);
        }else{
          switch (data.action) {
            case "blockedAccount":
              this.globalService.sendRequest({ type: 'BLOCKED_ACCOUNT' });
              break;
  
            default:
              this.chatMessage.next(data);
              break;
          }
        }
      };
  
      this.webSocket.onclose = (event) => {
        this.statusRA = false;
        setTimeout(() => {
          console.log("reconectando")
          this.connectionWebSocket()
        }, 1000);
      };
    }else{
      //cerrar primero la conexion y volver a conectar
      this.webSocket.close();
    }
  }

  validateAuctionWss(action){
    let actions = [
      "initAuctionCloseEnvelope",
      "initAuctionReverseEnglish",
      "initAuctionByRanges",

      "initAuctionShippingCloseEnvelope",
      "initAuctionShippingReverseEnglish",
      "initAuctionShippingByRanges",

      "closeAuctionCloseEnvelope",
      "closeAuctionReverseEnglish",
      "closeAuctionByRanges",

      "sendReQuoteAuction",
      "acceptedQuoteAuction",
      "notParticipateQuoteAuction",

      "sendOfferAuctionCloseEnvelope",
      "sendOfferAuctionReverseEnglish",
      "sendOfferAuctionByRanges",

      "reQuoteAuctionCloseEnvelope"
    ];
    return actions.includes(action);
  }

  //enviar accion al servidor
  send(data){
    if(this.webSocket && this.webSocket.readyState === this.webSocket.OPEN){
      this.webSocket.send(JSON.stringify(data));
    }
  }

  //cerrar websocket
  closeWebSocket() {
    this.webSocket.close();
  }

  //actualizar ruta del usuario
  updateRouteRA(){
    if(this.webSocket && this.webSocket.readyState === this.webSocket.OPEN){
      let typeSession = this.globalService.getTypeSession();
      this.send({
        "action": "routeUpdate",
        "data": {
          token: this.sessionService.token,
          route: typeSession['route'],
          idCompany: typeSession['idCompany']
        }
      });
    }
  }

  //actualizar registro de conexion, enviando el token para validacion
  getInfoUserCrypto(){
    //let resulIp = await firstValueFrom(this.globalService.getIp());
    let infoDevice = this.deviceService.getDeviceInfo();
    let tablet = this.deviceService.isTablet();
    let mobile = this.deviceService.isMobile();
    let device = mobile ? 'Mobile' : tablet ? 'Tablet' : 'Desktop';
    let typeSession = this.globalService.getTypeSession();
    let data = {
      token: this.sessionService.token,
      ip: "NOT_IP",
      route: typeSession.route,
      os: infoDevice.os,
      browser: infoDevice.browser,
      device: device,
      idCompany: typeSession.idCompany,
      type: "PLATFORM"
    };
    
    let url = `${environment.baseWssAPI}?xxlsx=${data.token}`;
    if(data.browser.length > 0){
      url = url + `&browser=${data.browser}`;
    }
    if(data.device.length > 0){
      url = url + `&device=${data.device}`;
    }
    if(data.idCompany.length > 0){
      url = url + `&idCompany=${data.idCompany}`;
    }
    if(data.os.length > 0){
      url = url + `&os=${data.os}`;
    }
    if(data.route.length > 0){
      url = url + `&route=${data.route}`;
    }
    if(data.type.length > 0){
      url = url + `&type=${data.type}`;
    }

    return url;
  }

}
